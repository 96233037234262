<template>
  <v-container v-if="$currentUser && $currentUser.hasPermission('SHOW_ADMIN_MENU')" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success">
            <v-toolbar-title class="white--text">Brukere</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="editUser({ active: true })">
              <v-icon>person_add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear v-if="!users" indeterminate></v-progress-linear>
          <v-list two-line class="py-0">
            <v-list-item
              v-for="user in users"
              :key="user.user_id"
              :class="user.active ? '' : 'inactive-user'"
            >
              <v-list-item-content>
                <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row class="align-center">
                    <v-col>
                      <v-icon class="mr-2">email</v-icon>
                      <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                    </v-col>
                    <v-col>
                      <v-icon class="mr-2">smartphone</v-icon>
                      {{ user.phone | phone }}
                    </v-col>
                    <v-col class="hidden-xs-only">
                      <span>Aktiv:</span>
                      {{ user.active ? 'Ja' : 'Nei' }}
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon @click="editUser(user)">
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-alert
            v-model="generalErrorAlert"
            dismissible
            type="error"
            transition="slide-y-transition"
            class="mb-0"
            @input="generalErrorMsg = ''"
            >{{ generalErrorMsg }}</v-alert
          >
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showUserDialog" width="400" persistent>
      <v-card v-if="selectedUser">
        <v-card-title class="headline primary white--text">
          <template v-if="selectedUser.user_id">Rediger bruker</template>
          <template v-else>Opprett bruker</template>
          <v-spacer />
          <v-btn v-if="!loading" icon color="white" @click="closeUserDialog">
            <v-icon>close</v-icon>
          </v-btn>
          <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" autocomplete="off">
            <v-text-field
              v-model="selectedUser.first_name"
              label="Fornavn"
              :rules="[$validationRules.required]"
              :disabled="loading"
              @blur="handleFirstNameField"
              @keyup.enter="handleFirstNameField"
            ></v-text-field>
            <v-text-field
              v-model="selectedUser.last_name"
              label="Etternavn"
              :rules="[$validationRules.required]"
              :disabled="loading"
              @blur="handleLastNameField"
              @keyup.enter="handleLastNameField"
            ></v-text-field>
            <v-text-field
              v-model="selectedUser.email"
              label="Epost"
              :rules="[$validationRules.required, $validationRules.email]"
              :disabled="loading"
              @blur="handleEmailField"
              @keyup.enter="handleEmailField"
            ></v-text-field>
            <v-text-field
              v-model="selectedUser.phone"
              label="Telefon"
              :rules="[$validationRules.required]"
              :disabled="loading"
              @blur="handlePhoneField"
              @keyup.enter="handlePhoneField"
            ></v-text-field>
            <v-select
              v-model="selectedUser.permissions"
              :items="permissions"
              label="Rettigheter"
              item-text="name"
              return-object
              multiple
            />
            <v-row class="align-center justify-space-between">
              <v-col>
                <v-switch
                  v-model="selectedUser.active"
                  :label="selectedUser.active ? 'Aktiv' : 'Inaktiv'"
                  color="success"
                  :disabled="loading"
                  @change="handleActiveSwitch"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="error"
                  icon
                  :disabled="showDeleteConfirm || loading"
                  @click="showDeleteConfirm = true"
                >
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="showDeleteConfirm" class="grey--lighten-3">
              <v-col>
                <v-btn color="error" :disabled="loading" @click="deleteUser(selectedUser)">
                  <v-icon>delete_forever</v-icon>
                  Slett {{ selectedUser.first_name }}
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" text :disabled="loading" @click="showDeleteConfirm = false"
                  >Avbryt</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <ErrorAlert v-bind="errorAlert" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import currentUser from '@/mixins/currentUser.js'
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import validationRules from '@/mixins/validationRules.js'
import ErrorAlert from '@/components/ErrorAlert'
import Permission from '@/models/Permission'
import User from '@/models/User'
import UserPermission from '@/models/UserPermission'

export default {
  components: {
    ErrorAlert,
  },
  mixins: [currentUser, entityPersistence, errorAlert, validationRules],
  data: () => {
    return {
      loading: false,
      selectedUser: null,
      showUserDialog: false,
      showDeleteConfirm: false,
      generalErrorAlert: false,
      generalErrorMsg: '',
      permissionCheckboxes: {},
      rules: {
        required: value => !!value || 'Påkrevd',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ugyldig epostadresse'
        },
      },
    }
  },
  computed: {
    entity() {
      return this.selectedUser
    },
    users() {
      return User.query()
        .withAll()
        .orderBy('active', 'desc')
        .orderBy('first_name')
        .get()
    },
    permissions() {
      return Permission.query()
        .withAll()
        .orderBy('name')
        .get()
    },
  },
  watch: {
    'selectedUser.permissions'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedAdvancedRelatedEntities(newEntities, oldEntities, Permission, UserPermission)
      this.removeRelatedEntities(newEntities, oldEntities)
      return
    },
  },
  methods: {
    closeUserDialog() {
      this.selectedUser = null
      this.showUserDialog = false
    },
    editUser(user) {
      this.selectedUser = user
      this.showUserDialog = true
    },
    async handleFirstNameField() {
      if (!this.showUserDialog || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.selectedUser.user_id) {
        await this.createOrUpdateEntity(
          User,
          { first_name: this.selectedUser.first_name },
          this.selectedUser.user_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleLastNameField() {
      if (!this.showUserDialog || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.selectedUser.user_id) {
        await this.createOrUpdateEntity(
          User,
          { last_name: this.selectedUser.last_name },
          this.selectedUser.user_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleEmailField() {
      if (!this.showUserDialog || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.selectedUser.user_id) {
        await this.createOrUpdateEntity(
          User,
          { email: this.selectedUser.email },
          this.selectedUser.user_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handlePhoneField() {
      if (!this.showUserDialog || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.selectedUser.user_id) {
        await this.createOrUpdateEntity(
          User,
          { phone: this.selectedUser.phone },
          this.selectedUser.user_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleActiveSwitch() {
      if (!this.showUserDialog || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.selectedUser.user_id) {
        await this.createOrUpdateEntity(
          User,
          { active: this.selectedUser.active },
          this.selectedUser.user_id
        )
      } else {
        await this.createEntity()
      }
    },
    async deleteUser(user) {
      this.loading = true
      this.clearErrorAlert()

      try {
        await User.deleteOne(user.user_id)
        this.selectedUser = null
        this.showDeleteConfirm = false
        this.showUserDialog = false
      } catch (e) {
        this.handleError(`Det oppsto en feil: ${e.message}`)
      }
      this.loading = false
    },
    async createEntity() {
      this.clearErrorAlert()
      this.selectedUser = await this.createOrUpdateEntity(User, {
        first_name: this.selectedUser.first_name,
        last_name: this.selectedUser.last_name,
        email: this.selectedUser.email,
        phone: this.selectedUser.phone,
      })
    },
  },
}
</script>

<style lang="less" scoped>
ul {
  padding-left: 0;
}

li {
  display: inline-block;
  margin: 1em 2em 1em 0;
}

.inactive-user {
  background-color: #eee;

  .theme--dark & {
    background-color: #000;
  }
}

.permission {
  font-size: 0.875em;
}
</style>

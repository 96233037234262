<template>
  <v-container v-if="$currentUser">
    <v-layout wrap>
      <v-flex xs12>
        <v-alert
          v-model="generalErrorAlert"
          dismissible
          type="error"
          transition="slide-y-transition"
          @input="generalErrorMsg = ''"
          >{{ generalErrorMsg }}</v-alert
        >
        <v-toolbar class="secondary" dark>
          <v-toolbar-title>Bedrifter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$currentUser.hasPermission('COMPANY_CREATE')"
            icon
            @click="showCompanyDialog()"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- filters ----------------------------------------------------------------->
        <v-layout class="white px-4 py-2" align-center>
          <v-icon>filter_list</v-icon>
          <v-flex xs2 class="mr-2 ml-4">
            <v-text-field v-model="dataTable.search.name" label="Firmanavn" clearable />
          </v-flex>
          <v-flex xs2 class="mr-2">
            <v-text-field v-model="dataTable.search.orgnr" label="Orgnr" type="number" clearable />
          </v-flex>
          <v-flex xs2 class="mr-2">
            <v-text-field
              v-model="dataTable.search.billing_address"
              label="Fakturaadresse"
              clearable
            />
          </v-flex>
          <v-flex v-if="loading" xs2><v-progress-circular indeterminate color="primary"/></v-flex>
        </v-layout>

        <!-- companies table ------------------------------------------------------->
        <v-data-table
          :headers="companiesHeaders"
          :items="companies"
          :loading="loading"
          item-key="company_id"
          :footer-props="dataTable.footerProps"
          :server-items-length="dataTable.pagination.total"
          loading-text="Henter data"
          @update:options="updateDatatableOptions"
        >
          <template v-slot:item="companies">
            <tr v-if="!loading">
              <td>
                <router-link
                  :to="{
                    name: 'company',
                    params: { company_id: companies.item.company_id },
                  }"
                  >{{ companies.item.name }}</router-link
                >
              </td>
              <td class="white-space-nowrap">{{ companies.item.orgnr | orgnr }}</td>
              <td class="white-space-nowrap">
                <span v-for="user in companies.item.users" :key="user.user_id">
                  <template v-if="user.pivot.is_main_salesperson">{{ user.full_name }}</template>
                </span>
              </td>
              <td class="white-space-nowrap">
                <span v-if="companies.item.phonenumbers[0]">
                  {{ companies.item.phonenumbers[0].phonenumber | phone }}
                  <v-menu v-if="companies.item.phonenumbers.length > 1" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>list</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(phonenumber, index) in companies.item.phonenumbers"
                        :key="index"
                      >
                        <v-list-item-title>{{ phonenumber.phonenumber | phone }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </td>
              <td>
                <span v-if="companies.item.emailaddresses[0]">
                  <a :href="`mailto:${companies.item.emailaddresses[0].emailaddress}`">{{
                    companies.item.emailaddresses[0].emailaddress
                  }}</a>
                  <v-menu v-if="companies.item.emailaddresses.length > 1" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>list</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(emailaddress, index) in companies.item.emailaddresses"
                        :key="index"
                      >
                        <v-list-item-title>
                          <a :href="`mailto:${emailaddress.emailaddress}`">{{
                            emailaddress.emailaddress
                          }}</a>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </td>
              <td>{{ companies.item.billing_address }}</td>
              <td class="white-space-nowrap">
                <span v-if="companies.item.contactpersons[0]">
                  {{ companies.item.contactpersons[0].full_name }}
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon v-if="companies.item.contactpersons.length > 1">people</v-icon>
                        <v-icon v-else>person</v-icon>
                      </v-btn>
                    </template>
                    <v-list three-line>
                      <v-list-item
                        v-for="(contactperson, index) in companies.item.contactpersons"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ contactperson.full_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="contactperson.emailaddresses.length > 0">
                            E-post:
                            <a
                              v-for="contactpersonEmail in contactperson.emailaddresses"
                              :key="contactpersonEmail.emailaddress_id"
                              :href="`mailto:${contactpersonEmail.emailaddress}`"
                              class="contactperson-email"
                              >{{ contactpersonEmail.emailaddress }}</a
                            >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="contactperson.phonenumbers.length > 0">
                            Telefon:
                            <span
                              v-for="contactpersonPhone in contactperson.phonenumbers"
                              :key="contactpersonPhone.phonenumber_id"
                              class="contactperson-phone pr-2"
                              >{{ contactpersonPhone.phonenumber | phone }}</span
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </td>
              <td class="white-space-nowrap">
                <v-chip
                  v-for="industry in companies.item.industries"
                  :key="industry.industry_id"
                  color="orange"
                  text-color="white"
                  class="mr-1"
                  >{{ industry.name }}</v-chip
                >
              </td>
              <td>
                <v-btn
                  v-if="$currentUser.hasPermission('COMPANY_UPDATE')"
                  icon
                  @click="showCompanyDialog({ company: companies.item })"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <CompanyDialog
      :show="companyDialog.show"
      :company="companyDialog.company"
      @closeAndReset="closeAndResetCompanyDialog"
      @setNewEntity="setNewCompany"
    />
  </v-container>
</template>

<script>
import combineArrays from '@/mixins/combineArrays'
import companyDialog from '@/mixins/companyDialog'
import currentUser from '@/mixins/currentUser.js'
import updateDatatableOptions from '@/mixins/dataTable'
import responseHelper from '@/mixins/responseHelper'
import CompanyDialog from '@/components/CompanyDialog'
import Company from '@/models/Company'

export default {
  components: {
    CompanyDialog,
  },
  mixins: [combineArrays, companyDialog, currentUser, responseHelper, updateDatatableOptions],
  data: () => {
    return {
      generalErrorMsg: '',
      generalErrorAlert: false,
      loading: true,
      companiesHeaders: [
        { text: 'Navn', value: 'name' },
        { text: 'Orgnr', value: 'orgnr' },
        { text: 'Hovedselger', value: 'user_id' },
        { text: 'Telefon', value: 'phone', sortable: false },
        { text: 'E-post', value: 'email', sortable: false },
        { text: 'Fakturaadresse', value: 'billing_address' },
        { text: 'Kontaktperson', value: 'contactperson', sortable: false },
        { text: 'Bransjer', value: 'industries', sortable: false },
        { text: '', value: 'edit', sortable: false },
      ],
    }
  },
  computed: {
    companies() {
      return (
        Company.query()
          .with('phonenumbers')
          .with('industries')
          .with('emailaddresses')
          .with('contactpersons')
          .with('contactpersons.emailaddresses')
          .with('contactpersons.phonenumbers')
          .with('users')
          //TODO fix datatable ordering in production
          //.orderBy(this.dataTable.sort.sortBy, this.dataTable.sort.sortDesc ? 'desc' : 'asc')
          .orderBy('company_id', 'desc')
          .get()
      )
    },
  },
  methods: {
    setNewCompany(company) {
      this.companyDialog.company = company
    },
    async fetchData() {
      this.loading = true

      const deleteAll = true
      const relationships = [
        'emailaddresses',
        'phonenumbers',
        'contactpersons',
        'contactpersons.phonenumbers',
        'contactpersons.emailaddresses',
        'users',
      ]

      for (let filter in this.dataTable.search) {
        if (this.dataTable.search[filter] === null) {
          delete this.dataTable.search[filter]
        }
      }
      const params = {
        pagination: this.dataTable.pagination,
        sort: this.dataTable.sort,
        search: this.dataTable.search,
      }

      const companies = await Company.fetchPaginated(params, deleteAll, relationships)

      this.dataTable.pagination.total = companies.response.data.total

      this.loading = false
    },
  },
}
</script>

<style scoped>
.contactperson-email,
.contactperson-phone {
  margin-right: 0.5em;
}
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$currentUser && _vm.$currentUser.hasPermission('SHOW_ADMIN_MENU'))?_c(VContainer,{staticClass:"pa-16"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","lg":"6","offset-md":"2","offset-lg":"3"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"success"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Tillatelser")]),_c(VSpacer),(_vm.$currentUser.hasPermission('PERMISSION_CREATE'))?_c(VBtn,{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.showPermissionDialog({})}}},[_c(VIcon,[_vm._v("add")])],1):_vm._e()],1),(!_vm.permissions)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c(VList,_vm._l((_vm.permissions),function(permission){return _c(VListItem,{key:permission.permission_id},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(permission.name)}})],1),(_vm.$currentUser.hasPermission('PERMISSION_UPDATE'))?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.showPermissionDialog(permission)}}},[_c(VIcon,[_vm._v("edit")])],1):_vm._e(),(_vm.$currentUser.hasPermission('PERMISSION_DELETE'))?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deletePermission(permission)}}},[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("delete_forever")])],1):_vm._e()],1)}),1)],1)],1)],1),_c('EntityDialog',_vm._b({ref:"permissionDialog",on:{"setNewEntity":_vm.handlePermissionCreated}},'EntityDialog',_vm.permissionDialog,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container v-if="$currentUser && $currentUser.hasPermission('SHOW_ADMIN_MENU')" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success">
            <v-toolbar-title class="white--text">Tillatelser</v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="$currentUser.hasPermission('PERMISSION_CREATE')"
              color="white"
              icon
              @click="showPermissionDialog({})"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear v-if="!permissions" indeterminate></v-progress-linear>
          <v-list>
            <v-list-item v-for="permission in permissions" :key="permission.permission_id">
              <v-list-item-content>
                <v-list-item-title v-text="permission.name"></v-list-item-title>
              </v-list-item-content>

              <v-btn
                v-if="$currentUser.hasPermission('PERMISSION_UPDATE')"
                icon
                @click="showPermissionDialog(permission)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                v-if="$currentUser.hasPermission('PERMISSION_DELETE')"
                icon
                @click="deletePermission(permission)"
              >
                <v-icon color="error">delete_forever</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="permissionDialog"
      v-bind="permissionDialog"
      @setNewEntity="handlePermissionCreated"
    />
  </v-container>
</template>

<script>
import EntityDialog from '@/components/dialogs/EntityDialog.vue'
import currentUser from '@/mixins/currentUser.js'
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import validationRules from '@/mixins/validationRules'
import Permission from '@/models/Permission'

export default {
  components: { EntityDialog },
  mixins: [currentUser, entityPersistence, errorAlert, validationRules],
  data: () => {
    return {
      loading: false,
      selectedPermission: null,
    }
  },
  computed: {
    entity() {
      return this.selectedPermission
    },
    permissionDialog() {
      return {
        createTitle: 'Opprett tillatelse',
        editTitle: 'Rediger tillatelse',
        fields: [
          {
            type: 'text',
            model: Permission,
            name: 'name',
            label: 'Navn på tillatelse',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    permissions() {
      return Permission.query()
        .orderBy('name')
        .get()
    },
  },
  methods: {
    handlePermissionCreated(instance) {
      const permission = Permission.find(instance.permission_id)

      this.$refs.permissionDialog.entity = permission
      this.selectedPermission = permission

      this.closePermissionDialog()
    },
    async deletePermission(permission) {
      this.clearErrorAlert()

      try {
        await Permission.deleteOne(permission.permission_id)
        this.selectedPermission = null
      } catch (e) {
        console.log('e:', e)
      }
    },
    handleError(message) {
      this.$refs.permissionDialog.handleError(message)
      this.loading = false
    },
    showPermissionDialog(permission) {
      this.selectedPermission = permission
      this.$refs.permissionDialog.showDialog(Permission, permission)
    },
    closePermissionDialog() {
      this.$refs.permissionDialog.closeDialog()
      this.selectedPermission = null
    },
  },
}
</script>

import { Database } from '@vuex-orm/core'
import Apikey from '@/models/Apikey'
import ApikeyPermission from '@/models/ApikeyPermission'
import User from '@/models/User'
import Permission from '@/models/Permission'
import UserPermission from '@/models/UserPermission'
import Company from '@/models/Company'
import Companycomment from '@/models/Companycomment'
import Contactperson from '@/models/Contactperson'
import Phonenumber from '@/models/Phonenumber'
import CompanyPhonenumber from '@/models/CompanyPhonenumber'
import CompanyContactperson from '@/models/CompanyContactperson'
import CompanyUser from '@/models/CompanyUser'
import Emailaddress from '@/models/Emailaddress'
import CompanyEmailaddress from '@/models/CompanyEmailaddress'
import ContactpersonEmailaddress from '@/models/ContactpersonEmailaddress'
import ContactpersonPhonenumber from '@/models/ContactpersonPhonenumber'
import Industry from '@/models/Industry'
import CompanyIndustry from '@/models/CompanyIndustry'
import Contract from '@/models/Contract'
import Agreement from '@/models/Agreement'
import Agreementtype from '@/models/Agreementtype'
import Acceptance from '@/models/Acceptance'
import Acceptancetype from '@/models/Acceptancetype'
import Webacceptance from '@/models/Webacceptance'
import Smsacceptance from '@/models/Smsacceptance'
import Autoacceptance from '@/models/Autoacceptance'
import Our_contactinfo from '@/models/Our_contactinfo'

const database = new Database()

database.register(Apikey, {})
database.register(ApikeyPermission, {})
database.register(User, {})
database.register(Permission, {})
database.register(UserPermission, {})
database.register(Company, {})
database.register(Companycomment, {})
database.register(Contactperson, {})
database.register(Phonenumber, {})
database.register(ContactpersonPhonenumber, {})
database.register(CompanyPhonenumber, {})
database.register(Emailaddress, {})
database.register(CompanyEmailaddress, {})
database.register(ContactpersonEmailaddress, {})
database.register(CompanyContactperson, {})
database.register(CompanyUser, {})
database.register(Industry, {})
database.register(CompanyIndustry, {})
database.register(Contract, {})
database.register(Agreement, {})
database.register(Agreementtype, {})
database.register(Acceptance, {})
database.register(Acceptancetype, {})
database.register(Webacceptance, {})
database.register(Smsacceptance, {})
database.register(Autoacceptance, {})
database.register(Our_contactinfo, {})

export default database

import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$currentUser)?_c(VDataTable,{staticClass:"contracts-table",attrs:{"headers":_vm.dataTableHeaders,"items":_vm.contracts,"loading":_vm.loading,"item-key":"id","loading-text":"Henter data","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(contracts){return [_c('tr',[_c('td',[(contracts.item.acceptance)?[_vm._v(" "+_vm._s(_vm._f("moment")(contracts.item.acceptance.timestamp,'DD.MM.YYYY'))+" ")]:_c('span',{staticClass:"font-italic grey--text"},[_vm._v("Ingen aksept")])],2),_c('td',[(contracts.item.agreements.length > 0)?_vm._l((contracts.item.agreements),function(agreement){return _c('span',{key:agreement.id},[(agreement.agreementtype)?_c(VChip,{staticClass:"mr-1"},[_vm._v(" "+_vm._s(agreement.agreementtype.name)+" "+_vm._s(agreement.duration_months)+"mnd - "+_vm._s(_vm._f("currency")(_vm.getDiscountedPrice(agreement.full_price, agreement.discount_percentage)))+" ")]):_vm._e()],1)}):_c('span',{staticClass:"font-italic grey--text"},[_vm._v("Ingen produkter")])],2),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getTotalAmount(contracts.item.agreements)))+" "),(_vm.getTotalAmount(contracts.item.agreements) === 0)?_c('span',{staticClass:"grey--text"},[_vm._v("-")]):_vm._e()]),_c('td',[(contracts.item.user)?[_vm._v(" "+_vm._s(contracts.item.user.first_name)+" "+_vm._s(contracts.item.user.last_name)+" ")]:_vm._e()],2),_c('td',[(
              _vm.$currentUser.hasPermission('SHOW_CONTRACT_FILE') &&
                contracts.item.signed_contract_url
            )?_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$openLinkInNewWindow(contracts.item.signed_contract_url)}}},[_c(VIcon,[_vm._v("zoom_in")])],1):_vm._e(),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.showEditContractSheet(contracts.item)}}},'TooltipButton',_vm.editContractButton,false))],1)])]}}],null,false,4199571330)}):_vm._e(),_c(VBottomSheet,{attrs:{"inset":"","persistent":"","max-width":"1000px"},model:{value:(_vm.editContractSheet.show),callback:function ($$v) {_vm.$set(_vm.editContractSheet, "show", $$v)},expression:"editContractSheet.show"}},[_c('EditContractSheet',_vm._b({attrs:{"disabled-form-fields":['company']},on:{"closeAndReset":_vm.closeAndResetEditContractSheet,"deletedContract":_vm.closeAndResetEditContractSheet,"acceptanceChanged":_vm.setNewContract}},'EditContractSheet',_vm.editContractSheet,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-16"},[_c('h1',{staticClass:"text-center mb-16"},[_vm._v("Velkommen til "),_c('strong',[_vm._v("agree")]),_vm._v(".ly")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'companies' }}},[_c(VCard,{staticClass:"py-16",attrs:{"color":"warning"}},[_c(VCardTitle,{staticClass:"justify-center flex-column base--text"},[_c(VIcon,{staticClass:"mb-2",attrs:{"color":"base","x-large":""}},[_vm._v("storefront")]),_vm._v(" Bedrifter ")],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'contactpersons' }}},[_c(VCard,{staticClass:"py-16",attrs:{"color":"primary"}},[_c(VCardTitle,{staticClass:"justify-center flex-column white--text"},[_c(VIcon,{staticClass:"mb-2",attrs:{"color":"white","x-large":""}},[_vm._v("person")]),_vm._v(" Kontaktpersoner ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
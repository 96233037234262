<template>
  <v-dialog v-if="apikey" value="show" persistent max-width="600px" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="headline primary white--text">
        {{ apikey.apikey_id ? 'Rediger API-nøkkel' : 'Opprett ny API-nøkkel' }}
        <v-spacer />
        <v-btn v-if="!loading" icon color="white" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" autocomplete="off">
          <v-text-field
            v-model="apikey.apikey"
            :type="apikey.apikey_id ? 'password' : 'text'"
            :class="apikey.locked ? 'mb-6' : ''"
            label="API-nøkkel"
            :rules="[$validationRules.required]"
            :disabled="loading || apikey.locked"
            :readonly="!loading && !apikey.locked"
            :hint="
              apikey.locked
                ? 'Kan ikke endres når API-nøkkelen er låst'
                : 'Husk å lagre API-nøkkelen eksternt - når den er lagret vil den ikke vises igjen'
            "
            persistent-hint
            :suffix="apikeyFieldSuffix"
            :append-icon="!apikey.apikey_id ? 'content_copy' : ''"
            @click:append="copyApikeyToClipboard"
            @blur="handleApikeyField"
            @keyup.enter="handleApikeyField"
          />
          <v-text-field
            v-model="apikey.name"
            label="Navn"
            :rules="[$validationRules.required]"
            :disabled="loading || apikey.locked"
            hint="Kan ikke endres når API-nøkkelen er låst"
            :persistent-hint="apikey.locked"
            @blur="handleNameField"
            @keyup.enter="handleNameField"
          />
          <v-select
            v-model="apikey.permissions"
            :items="permissions"
            label="Rettigheter"
            item-text="name"
            return-object
            multiple
            :disabled="loading || apikey.locked"
            hint="Kan ikke endres når API-nøkkelen er låst"
            :persistent-hint="apikey.locked"
          />
          <v-switch
            v-model="apikey.locked"
            label="Låst"
            hint="Når API-nøkkelen låses kan den ikke åpnes igjen"
            persistent-hint
            :disabled="loading || !apikey.apikey_id || apikey.locked"
            @change="handleLockedField"
          />
          <v-switch
            v-model="apikey.disabled"
            label="Deaktivert"
            hint="Når API-nøkkelen deaktiveres kan den ikke aktiveres igjen"
            persistent-hint
            :disabled="loading || !apikey.apikey_id || apikey.disabled"
            @change="handleDisabledField"
          />
        </v-form>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import validationRules from '@/mixins/validationRules.js'
import responseHelper from '@/mixins/responseHelper'
import ErrorAlert from '@/components/ErrorAlert'
import Apikey from '@/models/Apikey'
import ApikeyPermission from '@/models/ApikeyPermission'
import Permission from '@/models/Permission'

export default {
  name: 'ApikeyDialog',
  components: {
    ErrorAlert,
  },
  mixins: [entityPersistence, errorAlert, responseHelper, validationRules],
  props: {
    show: Boolean,
    apikey: {
      type: Apikey,
      default: null,
    },
  },
  data: () => {
    return {
      apikeyFieldSuffix: '',
      loading: false,
    }
  },
  computed: {
    entity() {
      return this.apikey
    },
    permissions() {
      return Permission.query()
        .orderBy('name')
        .get()
    },
  },
  watch: {
    'apikey.permissions'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedAdvancedRelatedEntities(
        newEntities,
        oldEntities,
        Permission,
        ApikeyPermission
      )
      this.removeRelatedEntities(newEntities, oldEntities)
      return
    },
  },
  methods: {
    async handleNameField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.apikey.apikey_id) {
        await this.createOrUpdateEntity(Apikey, { name: this.apikey.name }, this.apikey.apikey_id)
      } else {
        await this.createEntity()
      }
    },
    async handleApikeyField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.apikey.apikey_id) {
        await this.createOrUpdateEntity(
          Apikey,
          { apikey: this.apikey.apikey },
          this.apikey.apikey_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleLockedField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.apikey.apikey_id) {
        await this.createOrUpdateEntity(
          Apikey,
          { locked: this.apikey.locked },
          this.apikey.apikey_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleDisabledField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.apikey.apikey_id) {
        await this.createOrUpdateEntity(
          Apikey,
          { disabled: this.apikey.disabled },
          this.apikey.apikey_id
        )
      } else {
        await this.createEntity()
      }
    },
    async createEntity() {
      this.clearErrorAlert()
      await this.createOrUpdateEntity(Apikey, {
        apikey_id: this.apikey.apikey_id,
        name: this.apikey.name,
        apikey: this.apikey.apikey,
      })
    },
    async copyApikeyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.apikey.apikey)
        this.apikeyFieldSuffix = 'Kopiert'
      } catch (err) {
        this.apikeyFieldSuffix = 'Kunne ikke kopiere'
      }
    },
    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },
    closeDialog() {
      this.clearErrorAlert()
      this.apikeyFieldSuffix = ''
      this.$emit('closeAndReset')
    },
  },
}
</script>

<template>
  <nav>
    <v-toolbar color="primary" dark>
      <v-toolbar-title class="headline">
        <span>agree</span>
        <span class="font-weight-light">.ly</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isAuthenticated">
        <v-btn v-for="link in links" :key="link.text" :to="{ name: link.route }" text exact>{{
          link.text
        }}</v-btn>
        <v-menu
          v-if="$currentUser && $currentUser.hasPermission('SHOW_ADMIN_MENU')"
          close-on-content-click
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">Administrasjon</v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                if ($route.name !== 'users') {
                  $router.push({ name: 'users' })
                }
              "
            >
              <v-list-item-title>Brukere</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'permissions') {
                  $router.push({ name: 'permissions' })
                }
              "
            >
              <v-list-item-title>Tillatelser</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'apikeys') {
                  $router.push({ name: 'apikeys' })
                }
              "
            >
              <v-list-item-title>API-nøkler</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text @click="logout">
          <span class="mr-2">Logg ut</span>
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-if="!isAuthenticated">
        <v-btn text @click="login">
          <span class="mr-2">Logg inn</span>
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
import currentUser from '@/mixins/currentUser.js'

export default {
  mixins: [currentUser],
  data() {
    return {
      links: [
        { route: 'home', text: 'Hjem' },
        { route: 'companies', text: 'Bedrifter' },
        { route: 'contactpersons', text: 'Kontaktpersoner' },
        //{ route: 'contracts', text: 'Kontrakter' },
        { route: 'profile', text: 'Profil' },
      ],
      isAuthenticated: false,
    }
  },
  methods: {
    login() {
      this.$auth.login()
    },
    logout() {
      this.$auth.logOut()
    },
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn
    },
  },
}
</script>

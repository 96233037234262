import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,{staticClass:"headline"},[_c('span',[_vm._v("agree")]),_c('span',{staticClass:"font-weight-light"},[_vm._v(".ly")])]),_c(VSpacer),(_vm.isAuthenticated)?_c(VToolbarItems,[_vm._l((_vm.links),function(link){return _c(VBtn,{key:link.text,attrs:{"to":{ name: link.route },"text":"","exact":""}},[_vm._v(_vm._s(link.text))])}),(_vm.$currentUser && _vm.$currentUser.hasPermission('SHOW_ADMIN_MENU'))?_c(VMenu,{attrs:{"close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":""}},on),[_vm._v("Administrasjon")])]}}],null,false,3375735308)},[_c(VList,[_c(VListItem,{on:{"click":function($event){if (_vm.$route.name !== 'users') {
                _vm.$router.push({ name: 'users' })
              }}}},[_c(VListItemTitle,[_vm._v("Brukere")])],1),_c(VListItem,{on:{"click":function($event){if (_vm.$route.name !== 'permissions') {
                _vm.$router.push({ name: 'permissions' })
              }}}},[_c(VListItemTitle,[_vm._v("Tillatelser")])],1),_c(VListItem,{on:{"click":function($event){if (_vm.$route.name !== 'apikeys') {
                _vm.$router.push({ name: 'apikeys' })
              }}}},[_c(VListItemTitle,[_vm._v("API-nøkler")])],1)],1)],1):_vm._e(),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.logout}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logg ut")]),_c(VIcon,[_vm._v("exit_to_app")])],1)],2):_vm._e(),(!_vm.isAuthenticated)?_c(VToolbarItems,[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.login}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logg inn")]),_c(VIcon,[_vm._v("exit_to_app")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$currentUser)?_c(VContainer,{staticClass:"pa-16"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","lg":"6","offset-md":"2","offset-lg":"3"}},[_c(VCard,[_c(VToolbar,{staticClass:"white--text",attrs:{"color":"success"}},[_c(VToolbarTitle,[_vm._v("API-nøkler")]),_c(VSpacer),_c('TooltipButton',_vm._b({on:{"click":_vm.showApikeyDialog}},'TooltipButton',_vm.createApikeyButton,false))],1),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c(VList,_vm._l((_vm.apikeys),function(apikey){return _c(VListItem,{key:apikey.apikey_id},[_c(VListItemContent,{class:("" + (apikey.disabled ? 'apikey--disabled' : ''))},[_c(VListItemTitle,[_vm._v(" "+_vm._s(apikey.name)+" ")]),_c(VListItemSubtitle,[_c('strong',[_vm._v("ID:")]),_vm._v(" "+_vm._s(apikey.apikey_id)+" • "),_c('strong',[_vm._v("Rettigheter:")]),_vm._l((apikey.permissions),function(permission){return _c('span',{key:permission.permission_id},[_vm._v(" "+_vm._s(permission.name)+" ")])})],2)],1),_c('TooltipButton',{attrs:{"button":{
                icon: 'edit',
                disabled:
                  !_vm.$currentUser.hasPermission('APIKEY_UPDATE') || _vm.loading || apikey.disabled,
              },"tooltip":{
                openDelay: 500,
                bottom: true,
                text: _vm.$currentUser.hasPermission('APIKEY_UPDATE')
                  ? 'Rediger API-nøkkel'
                  : 'Du mangler tillatelse til å redigere API-nøkler',
              }},on:{"click":function($event){return _vm.showApikeyDialog(apikey)}}})],1)}),1)],1)],1)],1),_c('ApikeyDialog',_vm._b({on:{"entityCreated":_vm.handleApikeyCreated,"entityUpdated":_vm.handleApikeyUpdated,"closeAndReset":_vm.closeAndResetApikeyDialog}},'ApikeyDialog',_vm.apikeyDialog,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="pa-16">
    <h1 class="text-center mb-16">Velkommen til <strong>agree</strong>.ly</h1>
    <v-row>
      <v-col cols="12" sm="6">
        <router-link :to="{ name: 'companies' }" style="text-decoration: none;">
          <v-card color="warning" class="py-16">
            <v-card-title class="justify-center flex-column base--text">
              <v-icon class="mb-2" color="base" x-large>storefront</v-icon>
              Bedrifter
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12" sm="6">
        <router-link :to="{ name: 'contactpersons' }" style="text-decoration: none;">
          <v-card color="primary" class="py-16">
            <v-card-title class="justify-center flex-column white--text">
              <v-icon class="mb-2" color="white" x-large>person</v-icon>
              Kontaktpersoner
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<template>
  <div>
    <v-container v-if="$currentUser && company">
      <h1 class="mb-8">{{ company.name }}</h1>
      <v-row class="justify-space-between mb-2">
        <v-col cols="12" xl="9">
          <v-row>
            <v-col cols="12" sm="6" md="3" xl="4">
              <v-card class="mb-4">
                <v-card-title class="justify-space-between primary white--text">
                  Bedriftsdetaljer
                  <!--
                  <TooltipButton
                  v-bind="updateCompanyButton"
                  @click="$refs.companyDialog.showDialog(company)"
                />
                -->
                </v-card-title>
                <v-card-text class="pa-4 grey--lighten-4">
                  <v-chip
                    v-for="industry in company.industries"
                    :key="industry.industry_id"
                    class="orange mb-2"
                  >
                    {{ industry.name }}
                  </v-chip>
                  <h3>Org.nr:</h3>
                  <a :href="`https://proff.no/bransjes%C3%B8k?q=${company.orgnr}`" target="_blank"
                    >{{ company.orgnr | orgnr }}
                  </a>
                  <h3 class="mt-4 mb-1">Kontaktinformasjon:</h3>
                  <h4 v-if="company.phonenumbers.length" class="mt-2">Telefon:</h4>
                  <a
                    v-for="phonenumber in company.phonenumbers"
                    :key="phonenumber.phonenumber_id"
                    :href="`tel:${phonenumber.phonenumber}`"
                    class="d-block mb-1"
                  >
                    {{ phonenumber.phonenumber | phone }}
                  </a>
                  <h4 v-if="company.emailaddresses.length" class="mt-4">E-post:</h4>
                  <a
                    v-for="emailaddress in company.emailaddresses"
                    :key="emailaddress.emailaddress"
                    :href="`mailto:${emailaddress.emailaddress}`"
                    class="d-block mb-1"
                  >
                    {{ emailaddress.emailaddress }}
                  </a>
                  <h4 v-if="company.billing_address" class="mt-4">Fakturaadresse:</h4>
                  {{ company.billing_address }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card class="mb-4">
                <v-card-title class="justify-space-between primary white--text"
                  >Selgere
                  <TooltipButton
                    v-bind="addSalespersonButton"
                    @click="
                      $refs.companyUserDialog.showDialog({
                        company_id: company.company_id,
                        company: company,
                      })
                    "
                /></v-card-title>
                <v-progress-linear v-if="loadingNewMainSalesperson" indeterminate />
                <v-card-text v-if="company.users && company.users.length" class="pa-4">
                  <ul class="salespersons">
                    <li
                      v-for="salesperson in company.users"
                      :key="salesperson.user_id"
                      class="salesperson"
                      :class="salesperson.pivot.is_main_salesperson ? 'salesperson--is-main' : ''"
                    >
                      <div>
                        <v-icon
                          class="salesperson__icon mr-2"
                          @click="
                            $currentUser.hasPermission('COMPANY_SET_MAIN_SALESPERSON') &&
                              setNewMainSalesperson(salesperson)
                          "
                          >star</v-icon
                        >{{ salesperson.full_name }}
                      </div>
                      <v-icon
                        v-if="$currentUser.hasPermission('COMPANY_USER_DELETE')"
                        color="error"
                        @click="removeSalesperson(salesperson)"
                        >delete_forever</v-icon
                      >
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" xl="4">
              <v-card class="mb-4">
                <v-card-title class="justify-space-between primary white--text">
                  Kontaktperson<template v-if="company.contactpersons.length > 1">er</template>
                </v-card-title>
                <v-card-text v-if="company.contactpersons.length" class="pa-2  grey--lighten-4">
                  <div
                    v-for="contactperson in company.contactpersons"
                    :key="contactperson.contactperson_id"
                    class="rounded white has-soft-box-shadow ma-2 pa-2"
                  >
                    <h3 class="m`b-2">{{ contactperson.full_name }}</h3>

                    <v-row
                      v-if="
                        contactperson.emailaddresses.length || contactperson.phonenumbers.length
                      "
                    >
                      <v-col
                        v-if="contactperson.emailaddresses.length"
                        cols="12"
                        sm="7"
                        class="pb-0 pb-sm-3"
                      >
                        <h4>E-post:</h4>
                        <a
                          v-for="emailaddress in contactperson.emailaddresses"
                          :key="emailaddress.emailaddress"
                          :href="`mailto:${emailaddress.emailaddress}`"
                          class="d-block mb-1"
                        >
                          {{ emailaddress.emailaddress }}
                        </a>
                      </v-col>
                      <v-col v-if="contactperson.phonenumbers.length" cols="12" sm="5">
                        <h4>Telefon:</h4>
                        <a
                          v-for="phonenumber in contactperson.phonenumbers"
                          :key="phonenumber.phonenumber"
                          :href="`tel:${phonenumber.phonenumber}`"
                          class="d-block mb-1"
                        >
                          {{ phonenumber.phonenumber | phone }}
                        </a>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card v-if="$currentUser.hasPermission('CONTRACT_READ')">
            <v-card-title class="d-flex justify-space-between primary white--text">
              Kontrakter
              <TooltipButton v-bind="createContractButton" @click="showCreateContractSheet()"
            /></v-card-title>
            <v-card-text>
              <ContractsTable :contracts="company.contracts" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3">
          <v-card v-if="$currentUser.hasPermission('COMPANYCOMMENT_READ')" class="mb-4">
            <v-card-title class="justify-space-between primary white--text"
              >Kommentarer</v-card-title
            >
            <v-card-text class="pa-4 grey--lighten-4">
              <Comment
                v-for="comment in topLevelComments"
                :key="comment.companycomment_id"
                :comment-id="comment.companycomment_id"
                class="white mb-3 pa-3 rounded grey--text text--darken-3 has-soft-box-shadow"
              />
              <div class="d-flex align-center">
                <v-textarea
                  v-model="addedComment"
                  :loading="loading"
                  rows="1"
                  auto-grow
                  label="Legg til kommentar"
                  variant="underlined"
                  :disabled="!$currentUser.hasPermission('COMPANYCOMMENT_CREATE')"
                  :error-messages="addCommentError"
                />
                <v-btn
                  :disabled="loading || !addedComment"
                  icon
                  color="error"
                  @click="addedComment = null"
                  ><v-icon>close</v-icon>
                </v-btn>
                <v-btn
                  :disabled="loading || !addedComment"
                  icon
                  color="success"
                  @click="saveAddedComment"
                  ><v-icon>send</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet v-model="editContractSheet.show" inset persistent max-width="1000px">
      <EditContractSheet
        v-bind="editContractSheet"
        :disabled-form-fields="['company']"
        @setNewEntity="setNewContract"
        @closeAndReset="closeAndResetEditContractSheet"
        @deletedContract="closeAndResetEditContractSheet"
    /></v-bottom-sheet>
    <CompanyUserDialog ref="companyUserDialog" />
  </div>
</template>

<script>
import color from '@/mixins/color'
import companyUserDialog from '@/mixins/dialogs/companyUserDialog.js'
import editContractSheet from '@/mixins/editContractSheet'
import currentUser from '@/mixins/currentUser.js'
import dateAndTime from '@/mixins/dateAndTime.js'
import responseHelper from '@/mixins/responseHelper'
import stringHelpers from '@/mixins/stringHelpers'
import Company from '@/models/Company'
import Companycomment from '@/models/Companycomment'
import CompanyUser from '@/models/CompanyUser'
import EditContractSheet from '@/components/EditContractSheet'
import ContractsTable from '@/components/ContractsTable'
import Comment from '@/components/Comment'
import TooltipButton from '@/components/buttons/TooltipButton'
import CompanyUserDialog from '@/components/dialogs/CompanyUserDialog'

export default {
  components: { ContractsTable, Comment, CompanyUserDialog, EditContractSheet, TooltipButton },
  mixins: [
    color,
    companyUserDialog,
    currentUser,
    dateAndTime,
    editContractSheet,
    responseHelper,
    stringHelpers,
  ],
  data: () => {
    return {
      loading: false,
      addedComment: null,
      addCommentError: null,
      loadingNewMainSalesperson: false,
    }
  },
  computed: {
    company() {
      return Company.query()
        .withAllRecursive()
        .whereId(parseInt(this.$route.params.company_id))
        .first()
    },
    topLevelComments() {
      return this.company?.companycomments.filter(comment => comment.parent_id == null)
    },
    addSalespersonButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('COMPANY_USER_CREATE') || this.loading,
          color: 'white',
          dark: true,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COMPANY_USER_CREATE')
            ? 'Knytt selger til bedrift'
            : 'Du mangler tillatelse til å knytte selgere til bedrifter',
        },
      }
    },
  },
  async beforeCreate() {
    try {
      const id = parseInt(this.$route.params.company_id)
      const deleteAll = false
      const relationships = [
        'emailaddresses',
        'phonenumbers',
        'companycomments',
        'contactpersons',
        'contactpersons.emailaddresses',
        'contactpersons.phonenumbers',
        'contracts',
        'contracts.acceptance',
        'contracts.agreements',
        'contracts.contactperson',
        'industries',
        'users',
      ]
      await Company.fetchOne(id, deleteAll, relationships)
    } catch (err) {
      console.error('Error fetching company: ', err)
    }
  },
  methods: {
    showCreateContractSheet() {
      this.showEditContractSheet({
        company_id: this.company.company_id,
        company: this.company,
        user_id: this.$currentUser.user_id,
        billing_address: this.company.billing_address,
        contactperson: this.company.firstContactperson,
        contactperson_id: this.company.firstContactperson?.contactperson_id,
        contact_name: this.company.firstContactperson?.full_name,
        contact_email: this.company.firstContactperson?.firstEmailaddress.emailaddress,
        contact_phone: this.company.firstContactperson?.firstPhonenumber.phonenumber,
      })
    },
    async saveAddedComment() {
      try {
        this.loading = true

        const addedComment = {
          company_id: this.company.company_id,
          user_id: this.$currentUser.user_id,
          text: this.addedComment,
        }

        await Companycomment.createOne(addedComment)
        this.addedComment = null
      } catch (err) {
        this.addCommentError = err.message
      } finally {
        this.loading = false
      }
    },
    async setNewMainSalesperson(salesperson) {
      if (salesperson.pivot.is_main_salesperson) return

      this.loadingNewMainSalesperson = true
      try {
        await this.company.setNewMainSalesperson(salesperson)
      } catch (err) {
        console.error('Error setting new main salesperson:', err)
      }
      this.loadingNewMainSalesperson = false
    },
    async removeSalesperson(salesperson) {
      this.removingSalesperson = true
      try {
        await salesperson.pivot.delete()
        await CompanyUser.fetchByKey('company_id', this.company.company_id)
      } catch (err) {
        console.error('Error removing salesperson:', err)
      }
      this.removingSalesperson = false
    },
  },
}
</script>
<style lang="less" scoped>
.salesperson {
  &s {
    padding: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__icon {
    justify-content: flex-start !important;
    color: #ccc !important;
  }

  &--is-main .salesperson__icon {
    color: orange !important;
    pointer-events: none;
  }
}
</style>

<template>
  <v-container v-if="$currentUser">
    <v-layout wrap>
      <v-flex xs12>
        <v-toolbar class="secondary" dark>
          <v-toolbar-title>Kontaktpersoner</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$currentUser.hasPermission('CONTACTPERSON_CREATE')"
            icon
            @click="showContactpersonDialog()"
          >
            <v-icon>person_add</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- filters ----------------------------------------------------------------->
        <v-layout class="white px-4 py-2">
          <v-icon>filter_list</v-icon>
          <v-flex xs2 class="mr-2 ml-4">
            <v-text-field v-model.lazy="dataTable.search.first_name" label="Fornavn" clearable />
          </v-flex>
          <v-flex xs2 class="mr-2">
            <v-text-field v-model.lazy="dataTable.search.last_name" label="Etternavn" clearable />
          </v-flex>
          <v-flex v-if="loading" xs2><v-progress-circular indeterminate color="primary"/></v-flex>
        </v-layout>

        <!-- contactpersons table ------------------------------------------------------->
        <v-data-table
          :headers="contactpersonsHeaders"
          :items="contactpersons"
          :loading="loading"
          item-key="contactperson_id"
          :footer-props="dataTable.footerProps"
          :server-items-length="dataTable.pagination.total"
          loading-text="Henter data"
          @update:options="updateDatatableOptions"
        >
          <template v-slot:item="contactpersons">
            <tr v-if="!loading">
              <td>{{ contactpersons.item.first_name }}</td>
              <td>{{ contactpersons.item.last_name }}</td>
              <td class="white-space-nowrap">
                <span v-if="contactpersons.item.phonenumbers[0]">
                  {{ contactpersons.item.phonenumbers[0].phonenumber | phone }}
                  <v-menu v-if="contactpersons.item.phonenumbers.length > 1" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>list</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(phonenumber, index) in contactpersons.item.phonenumbers"
                        :key="index"
                      >
                        <v-list-item-title>{{ phonenumber.phonenumber | phone }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </td>
              <td>
                <span v-if="contactpersons.item.emailaddresses[0]">
                  <a :href="`mailto:${contactpersons.item.emailaddresses[0].emailaddress}`">
                    {{ contactpersons.item.emailaddresses[0].emailaddress }}
                  </a>
                  <v-menu v-if="contactpersons.item.emailaddresses.length > 1" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>list</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(emailaddress, index) in contactpersons.item.emailaddresses"
                        :key="index"
                      >
                        <v-list-item-title>
                          <a :href="`mailto:${emailaddress.emailaddress}`">
                            {{ emailaddress.emailaddress }}
                          </a>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </td>
              <td>
                <span v-if="contactpersons.item.companies[0]">
                  {{ contactpersons.item.companies[0].name }}
                  <v-menu v-if="contactpersons.item.companies.length > 1" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>list</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(company, index) in contactpersons.item.companies"
                        :key="index"
                      >
                        <v-list-item-title>{{ company.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </td>
              <td>
                <v-btn
                  v-if="$currentUser.hasPermission('CONTACTPERSON_UPDATE')"
                  icon
                  :title="contactpersonDialog.title"
                  @click="showContactpersonDialog({ contactperson: contactpersons.item })"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <ContactpersonDialog
      :show="contactpersonDialog.show"
      :contactperson="contactpersonDialog.contactperson"
      @closeAndReset="closeAndResetContactpersonDialog"
      @setNewEntity="setNewContactperson"
    ></ContactpersonDialog>
  </v-container>
</template>

<script>
import contactpersonDialog from '@/mixins/contactpersonDialog'
import currentUser from '@/mixins/currentUser.js'
import dataTable from '@/mixins/dataTable'
import responseHelper from '@/mixins/responseHelper'
import ContactpersonDialog from '@/components/ContactpersonDialog'
import Company from '@/models/Company'
import CompanyContactperson from '@/models/CompanyContactperson'
import Contactperson from '@/models/Contactperson'
import ContactpersonEmailaddress from '@/models/ContactpersonEmailaddress'
import ContactpersonPhonenumber from '@/models/ContactpersonPhonenumber'
import Emailaddress from '@/models/Emailaddress'
import Phonenumber from '@/models/Phonenumber'

export default {
  components: {
    ContactpersonDialog,
  },
  mixins: [contactpersonDialog, currentUser, responseHelper, dataTable],
  data: () => {
    return {
      loading: true,
      contactpersonsHeaders: [
        { text: 'Fornavn', value: 'first_name' },
        { text: 'Etternavn', value: 'last_name' },
        { text: 'Telefon', value: 'phone', sortable: false },
        { text: 'E-post', value: 'email', sortable: false },
        { text: 'Bedrift', value: 'companies', sortable: false },
        { text: '', value: 'edit', sortable: false },
      ],
    }
  },
  computed: {
    contactpersons() {
      return (
        Contactperson.query()
          .with('phonenumbers')
          .with('emailaddresses')
          .with('companies')
          //TODO fix datatable ordering in production
          //.orderBy(this.dataTable.sort.sortBy, this.dataTable.sort.sortDesc ? 'desc' : 'asc')
          .orderBy('contactperson_id', 'desc')
          .get()
      )
    },
  },
  methods: {
    setNewContactperson(contactperson) {
      this.contactpersonDialog.contactperson = contactperson
    },
    async fetchData() {
      this.loading = true

      const contactpersons = await Contactperson.fetchPaginated({
        pagination: this.dataTable.pagination,
        sort: this.dataTable.sort,
        search: this.dataTable.search,
      })
      this.dataTable.pagination.total = contactpersons.response.data.total

      if (this.dataTable.pagination.total > 0) {
        const contactpersonIds = this.getIdsFromEntities(contactpersons, 'contactperson_id')

        const [
          contactpersonPhonenumbers,
          contactpersonEmailaddresses,
          companyContactpersons,
        ] = await Promise.all([
          ContactpersonPhonenumber.fetchBatchByFirstField(contactpersonIds),
          ContactpersonEmailaddress.fetchBatchByFirstField(contactpersonIds),
          CompanyContactperson.fetchBatchBySecondField(contactpersonIds),
        ])

        const phonenumberIds = this.getIdsFromEntities(contactpersonPhonenumbers, 'phonenumber_id')
        const emailaddressIds = this.getIdsFromEntities(
          contactpersonEmailaddresses,
          'emailaddress_id'
        )
        const companyIds = this.getIdsFromEntities(companyContactpersons, 'company_id')

        Phonenumber.fetchBatch(phonenumberIds)
        Emailaddress.fetchBatch(emailaddressIds)
        Company.fetchBatch(companyIds)
      }

      this.loading = false
    },
  },
}
</script>

<style scoped>
.contactperson-email,
.contactperson-phone {
  margin-right: 0.5em;
}
</style>
